
import { configureStore } from "@reduxjs/toolkit";
import designsReducer from "./slice"; // Import your Redux slice

const store = configureStore({
  reducer: {
    designs: designsReducer, // Use your Redux slice as the reducer
  },
});

export default store;
