import React from 'react';
import "./ContactData.scss";
import { useDispatch, useSelector } from 'react-redux';
import {removeFormData } from '../../../redux/slice'; 

const ContactData = () => {

  const dispatch = useDispatch();
  const formDataList = useSelector((state) => state.designs.formDataList); 

  const handleRemove = (index) => {
    dispatch(removeFormData(index));
  };
  
  return (
    <div className='contact-data'> 
      <h2>Form Data List</h2>
      <ul>
        {formDataList.map((formData, index) => (
          <li key={index} className='contact-data-card'>
            <strong>Name:</strong> {formData.name}<br />
            <strong>Email:</strong> {formData.email}<br />
            <strong>Message:</strong> {formData.message}<br />
            <strong>Interest:</strong> {formData.interest}<br /><br />
            <button onClick={() => handleRemove(index)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactData;
