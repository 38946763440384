import React, { useState } from 'react';
import './Courseslist.scss';
import { FaCartPlus } from 'react-icons/fa';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Courseslist = () => {

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [openIndex, setOpenIndex] = useState(null); // Initialize with null to have no item open initially

  const toggleAnswer = (index) => {
    if (index === openIndex) {
      // If the same content is clicked, close it
      setOpenIndex(null);
    } else {
      // If a different content is clicked, open it
      setOpenIndex(index);
    }
  };



  return (
    <div className='coureseslist'>
      <div className='coureseslist-head'>
        <div className='coureseslist-head-content'>
          <h1> COURSES DETAILS</h1>
        </div>

      </div>
        <div className='coureseslist-container'>
            
            <div className='coureseslist-content'>
              <div className='coureseslist-content-img'>
              <img src={require('../../../assets/img/upcoming1.jpeg')} alt="no-img" />

              </div>
              <div className='coureseslist-content-head' >

                <div className='coureseslist-title'>
                  <h1>Web Development</h1>
                </div>
                
              </div>
              <div className='coureseslist-main-details'>

              <div className="tab-container">
                <div className='tab-btns'> 
                  <button className={activeTab === 0 ? 'active tab-btn' : 'tab-btn'} onClick={() => handleTabClick(0)}>
                    OVERVIEW
                  </button>
                  <button
                    className={activeTab === 1 ? 'active tab-btn' : 'tab-btn'} onClick={() => handleTabClick(1)} >
                    Curriculum
                  </button>
                  <button
                    className={activeTab === 2 ? 'active tab-btn' : 'tab-btn'}onClick={() => handleTabClick(2)}>
                    Review
                  </button>

                </div>
                
              </div>
              <div className="tab-content">
                {activeTab === 0 && <div className='course-conent1'>
                  
                  <div className='course-conent1-text'>
                    <h1>OVERVIEW OF COURSE</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                    <p>Fusce convallis nulla nisl, in placerat orci viverra non. Suspendisse potenti. Phasellus tincidunt sapien ac neque dapibus venenatis vitae ac nulla. Curabitur non nisi maximus risus porttitor placerat eu sit amet elit. Suspendisse ac efficitur urna, at efficitur tortor. Quisque eget venenatis quam. Vestibulum lorem turpis, consectetur in orci nec, venenatis tempor lorem. Morbi tempor blandit lacinia. Quisque commodo, metus quis laoreet dictum, dui orci consequat quam, non cursus mi elit sagittis quam. Suspendisse rutrum finibus odio at vehicula. Maecenas vitae bibendum est. Integer ac sapien nisl. Phasellus in consectetur massa, ut cursus lacus. Donec venenatis eros est, sit amet tincidunt dui dignissim at. Ut tincidunt malesuada feugiat.</p>


                    <h1>
                    What Will You Learn?
                    </h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                    <p>Fusce convallis nulla nisl, in placerat orci viverra non. Suspendisse potenti. Phasellus tincidunt sapien ac neque dapibus venenatis vitae ac nulla. Curabitur non nisi maximus risus porttitor placerat eu sit amet elit. Suspendisse ac efficitur urna, at efficitur tortor. Quisque eget venenatis quam. Vestibulum lorem turpis, consectetur in orci nec, venenatis tempor lorem. Morbi tempor blandit lacinia. Quisque commodo, metus quis laoreet dictum, dui orci consequat quam, non cursus mi elit sagittis quam. Suspendisse rutrum finibus odio at vehicula. Maecenas vitae bibendum est. Integer ac sapien nisl. Phasellus in consectetur massa, ut cursus lacus. Donec venenatis eros est, sit amet tincidunt dui dignissim at. Ut tincidunt malesuada feugiat.</p>

                  
                  </div>
                  
                  </div>}
                {activeTab === 1 && <div className='course-conent2'>
                  
                  <div className='course-conent2-text'>
                      <div className='faq1'>
                        <h3 onClick={() => toggleAnswer(0)} style={{ cursor: 'pointer' }}>
                          content 1
                          {openIndex === 0 ? <FaMinus /> : <FaPlus />}
                        </h3>
                        <div className="faq-answer">
                          {openIndex === 0 && <>
                            <p>piont 1.</p>
                            <p>point 2</p>
                          </>
                          }
                        </div>
                      </div>

                      <div className='faq1'>
                        <h3 onClick={() => toggleAnswer(1)} style={{ cursor: 'pointer' }}>
                          content 2
                          {openIndex === 1 ? <FaMinus /> : <FaPlus />}
                        </h3>
                        <div className="faq-answer">
                          {openIndex === 1 && <p>Answer to content 2.</p>}
                        </div>
                      </div>

                      <div className='faq1'>
                        <h3 onClick={() => toggleAnswer(2)} style={{ cursor: 'pointer' }}>
                          content 3
                          {openIndex === 2 ? <FaMinus /> : <FaPlus />}
                        </h3>
                        <div className="faq-answer">
                          {openIndex === 2 && <p>Answer to content 3.</p>}
                        </div>
                      </div>
                      <div className='faq1'>
                        <h3 onClick={() => toggleAnswer(3)} style={{ cursor: 'pointer' }}>
                          content 4
                          {openIndex === 3 ? <FaMinus /> : <FaPlus />}
                        </h3>
                        <div className="faq-answer">
                          {openIndex === 3 && <p>Answer to content 3.</p>}
                        </div>
                      </div>
                      <div className='faq1'>
                        <h3 onClick={() => toggleAnswer(4)} style={{ cursor: 'pointer' }}>
                          content 5
                          {openIndex === 4 ? <FaMinus /> : <FaPlus />}
                        </h3>
                        <div className="faq-answer">
                          {openIndex === 4 && <p>Answer to content 3.</p>}
                        </div>
                      </div>
                      <div className='faq1'>
                        <h3 onClick={() => toggleAnswer(5)} style={{ cursor: 'pointer' }}>
                          content 6
                          {openIndex === 5 ? <FaMinus /> : <FaPlus />}
                        </h3>
                        <div className="faq-answer">
                          {openIndex === 5 && <p>Answer to content 3.</p>}
                        </div>
                      </div>
                      <div className='faq1'>
                        <h3 onClick={() => toggleAnswer(6)} style={{ cursor: 'pointer' }}>
                          content 7
                          {openIndex === 6 ? <FaMinus /> : <FaPlus />}
                        </h3>
                        <div className="faq-answer">
                          {openIndex === 6 && <p>Answer to content 3.</p>}
                        </div>
                      </div>
                    

                  </div>
               
                </div>}
                {activeTab === 2 && <div>
                  Review
                  
                  </div>}




              </div>


              </div>
                
            </div>
            <div className='coureseslist-details'>
              <div className='coureseslist-detail'>
                <h3>Course Features</h3>

                <div className='details-data'>
                  <p>Duration:</p>
                  <p>20hrs</p>
                </div>
                <hr></hr>
                <div className='details-data'>
                  <p>Lessons:</p>
                  <p>20hrs</p>
                </div>
                <hr></hr>
                <div className='details-data'>
                  <p>Skill Level:</p>
                  <p>Advance</p>
                </div>
                <div className='details-data'>
                  <p>Certifications:</p>
                  <p>Yes</p>
                </div>
                <hr></hr>
                <div className='details-data'>
                  <p>Language:</p>
                  <p>Tamil</p>
                </div>
                <div className='course-add-cart'><button><FaCartPlus /> <span>Add Cart</span></button></div>
              </div>
              <div className='coureseslist-latests'>
                <h3>latests courses</h3>

                <div className='coureseslist-lists'>
                  <div className='coureseslist-list1'>

                  </div>
                  <div className='coureseslist-list1'>

                  </div>

                </div>
              </div>

            </div>
        </div>
                  
    </div>
  )
}

export default Courseslist