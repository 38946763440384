
import React, { useState, useEffect } from 'react';
import './Form.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addFormData } from '../../redux/slice'; 




const Form = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [interest, setInterest] = useState('');
    const [errors, setErrors] = useState({});
  
    // Access form data from Redux store
    const formDataList = useSelector((state) => state.designs.formDataList); // Use the correct slice name
  
    const dispatch = useDispatch();
  
    useEffect(() => {
      // Load saved form data from Local Storage when the component mounts
      const savedFormData = localStorage.getItem('formDataList');
      if (savedFormData) {
        const parsedData = JSON.parse(savedFormData);
        // Dispatch an action to set form data in Redux store
        dispatch({ type: 'SET_FORM_DATA', payload: parsedData }); // Use the correct action type
      }
    }, [dispatch]);
  
    const validateForm = () => {
      const newErrors = {};
  
      if (!name.trim()) {
        newErrors.name = 'Name is required';
      }
  
      if (!email.trim()) {
        newErrors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        newErrors.email = 'Email is invalid';
      }
  
      if (!message.trim()) {
        newErrors.message = 'Message is required';
      }
  
      if (!interest) {
        newErrors.interest = 'Please select an interest';
      }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      if (validateForm()) {
        const formData = {
          name,
          email,
          message,
          interest,
        };
        dispatch(addFormData(formData)); 
        const updatedFormDataList = [...formDataList, formData];
        localStorage.setItem('formDataList', JSON.stringify(updatedFormDataList));
  
        setName('');
        setEmail('');
        setMessage('');
        setInterest('');
      }
    };
  
    const handleInputChange = (e) => {
      const fieldName = e.target.id;
      const fieldValue = e.target.value;
  
      if (errors[fieldName]) {
        setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));
      }
  
      if (fieldName === 'name') {
        setName(fieldValue);
      } else if (fieldName === 'email') {
        setEmail(fieldValue);
      } else if (fieldName === 'message') {
        setMessage(fieldValue);
      }
    };
    console.log(formDataList);
  return (
    <div className='form'>
        <h2>Register Now</h2>
          <form className="colorful-form" onSubmit={handleSubmit}>
  <div className="form-group">
    <label className="form-label" htmlFor="name">
      Name:
    </label>
    <input
      required
      placeholder="Enter your name"
      className="form-input"
      type="text"
      id="name"
      value={name}
      onChange={handleInputChange}
    />
    {errors.name && <div className="error">{errors.name}</div>}
  </div>
  <div className="form-group">
    <label className="form-label" htmlFor="email">
      Email:
    </label>
    <input
      required
      placeholder="Enter your email"
      className="form-input"
      type="email"
      id="email"
      value={email}
      onChange={handleInputChange}
    />
    {errors.email && <div className="error">{errors.email}</div>}
  </div>
  <div className="form-group">
    <label className="form-label" htmlFor="message">
      Message:
    </label>
    <textarea
      required
      placeholder="Enter your message"
      className="form-input"
      id="message"
      value={message}
      onChange={handleInputChange}
    />
    {errors.message && <div className="error">{errors.message}</div>}
  </div>
  <button className="form-button" type="submit">
    Submit
  </button>
</form>

    </div>
  )
}

export default Form