import React from 'react';
import './Courses.scss';
import { FaCartPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Courses = () => {
  // const [isClicks, setClicks] = useState([false, false, false]);

  // const handleHeartClick = (index) => {
  //   const newClicks = [...isClicks];
  //   newClicks[index] = !newClicks[index];
  //   setClicks(newClicks);
  // };

  // Define an array of design numbers and corresponding text

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };
 

  return (
    <div className='courses'>
      <div className='courses-container'>
        <div className='courses-head'>
          <h1>Our courses</h1>
        </div>

        <div className='courses-content'>
        
            <div className='courses-padding'>
              <div className='courses-card1'>
                <div className='courses-card1-img'>
                  <img src={require(`../../assets/img/upcoming1.jpeg`)} alt="no-img" />
                </div>
                <div className='courses-card1-text'>
                  <p className='course-tittle'> <Link to={"/coureses-list"} onClick={scrollToTop} >Web Development </Link></p>
                  {/* <p className='course-short-descr'>A product card is a page with product descriptions, features, images, prices, buying conditions, and customer reviews. </p>
                 */}


                </div>
                <div className='courses-btns'>
                  <div className='course-price'>$300</div>
                  <div className='course-add-cart'><button><FaCartPlus /> <span>Add Cart</span></button></div>
                </div>
              </div>
            </div>
            <div className='courses-padding'>
              <div className='courses-card1'>
                <div className='courses-card1-img'>
                  <img src={require(`../../assets/img/upcoming2.jpeg`)} alt="no-img" />
                </div>
                <div className='courses-card1-text'>
                  <p className='course-tittle'>App Development</p>
                  {/* <p className='course-short-descr'>A product card is a page with product descriptions, features, images, prices, buying conditions, and customer reviews. </p>
                 */}


                </div>
                <div className='courses-btns'>
                  <div className='course-price'>$300</div>
                  <div className='course-add-cart'><button><FaCartPlus /> <span>Add Cart</span></button></div>
                </div>
              </div>
            </div>
            <div className='courses-padding'>
              <div className='courses-card1'>
                <div className='courses-card1-img'>
                  <img src={require(`../../assets/img/upcoming7.jpeg`)} alt="no-img" />
                </div>
                <div className='courses-card1-text'>
                  <p className='course-tittle'>Reactjs </p>
                  {/* <p className='course-short-descr'>A product card is a page with product descriptions, features, images, prices, buying conditions, and customer reviews. </p>
                 */}


                </div>
                <div className='courses-btns'>
                  <div className='course-price'>$300</div>
                  <div className='course-add-cart'><button><FaCartPlus /> <span>Add Cart</span></button></div>
                </div>
              </div>
            </div>
            <div className='courses-padding'>
              <div className='courses-card1'>
                <div className='courses-card1-img'>
                  <img src={require(`../../assets/img/upcoming7.jpeg`)} alt="no-img" />
                </div>
                <div className='courses-card1-text'>
                  <p className='course-tittle'>Reactjs </p>
                  {/* <p className='course-short-descr'>A product card is a page with product descriptions, features, images, prices, buying conditions, and customer reviews. </p>
                 */}


                </div>
                <div className='courses-btns'>
                  <div className='course-price'>$300</div>
                  <div className='course-add-cart'><button><FaCartPlus /> <span>Add Cart</span></button></div>
                </div>
              </div>
            </div>
        
        </div>
      </div>
    </div>
  );
}

export default Courses;
