import React from 'react';
import './Testimonials.scss';

const Testimonials = () => {
  return (
    <div className='testimonials'>
        <div className='testimonials-container'>
            <div className='testimonials-content1' >
              <div className='testimonials-text'>
                <h1> Testimonials Us </h1>
                
                {/* <Link to={'/'} className='link-btn'> Learn More</Link> */}
              </div>

            </div>
            <div className='testimonials-content2'>
              
              <div className='testimonials-content2-text'>
                <h2> What are they say after using Lebris</h2>
              </div>

              <div className='reviews'>
                    <div className='review-card1'>
                        <div className='review-card-text'>
                        <p>Lebris has been instrumental in the success of our company. Their team's in-depth knowledge of tax regulations and seamless registration processes have allowed us to focus on our core business operations without worrying about compliance issues. Highly recommended! </p>
                        </div>
                        <div className='review-card-profile'>
                            <div className='review-profile-img'>
                                <span className='img'></span>
                            </div>
                            <div className='review-profile-text'>
                                <h4> EALIM NESA GIFTSON </h4>
                                <p>Woodern walls</p>
                            </div>
                        </div>
                    </div>
                    <div className='review-card1'>
                        <div className='review-card-text'>
                          <p> I am extremely pleased with the services provided by Lebris. From company registration to GST filing, they have been our reliable partner. The team's professionalism and commitment to excellence make them the go-to choice for any business looking for comprehensive financial solutions.</p>
                        </div>
                        <div className='review-card-profile'>
                            <div className='review-profile-img'>
                                

                            </div>
                            <div className='review-profile-text'>
                                <h4> Dinesh</h4>
                                <p>Graphic Design Agency</p>
                            </div>
                        </div>
                    </div>
              </div>
            </div>
            
        </div>
    </div>
  )
}

export default Testimonials