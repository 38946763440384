import { createSlice } from "@reduxjs/toolkit";

const designsReducer = createSlice({
  name: 'designs',
  initialState: {
    
    formDataList: [],

  },
  reducers: {

    addFormData: (state, action) => {
      state.formDataList.push(action.payload);
    },

    removeFormData: (state, action) => {
      state.formDataList.splice(action.payload, 1);
    },

    updateFormData: (state, action) => {
      const { index, newData } = action.payload;
      state.formDataList[index] = newData;
    },
  },
});

export const { login, addFormData, removeFormData, updateFormData } = designsReducer.actions;
export default designsReducer.reducer;
