import React, { useState, useEffect } from 'react';
import { HiMiniBars3 } from "react-icons/hi2";
import { HiXMark } from "react-icons/hi2";
import './Header.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const [scrolling, setScrolling] = useState(false);

  const closeNav = () => {
    setIsNavOpen(false); // Function to close the navigation menu
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={scrolling ? 'header scrolling-header' : 'header'}>
        <div className="header-container">
          <div className="logo">
            <Link to={"/"}><img src={require('../../assets/img/logo.png')} alt="no-logo" /> </Link>
          </div>
          <div className="nav-icon">
            <div onClick={toggleNav}>
              {isNavOpen ? <HiXMark /> : <HiMiniBars3 />}
            </div>
           
          </div>
          <div className={`nav ${isNavOpen ? 'responsive_nav' : ''}`}>
            <div className="nav-lists">
              <Link
                to="/gst-registeration"
                className="nav-list"
                onClick={() => {
                  closeNav();
                  scrollToTop();
                }}
              >
                Gst Registration
              </Link>
              <Link
                to="/income-tax-filling"
                className="nav-list"
                onClick={() => {
                  closeNav();
                  scrollToTop();
                }}
              >
                Income Tax Filing
              </Link>
              <Link
                to="/"
                className="nav-list"
                onClick={() => {
                  closeNav();
                  scrollToTop();
                }}
              >
                FSSAI Registration
              </Link>
              <Link
                to="/contact"
                className="nav-list"
                onClick={() => {
                  closeNav();
                  scrollToTop();
                }}
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
