
import React, { useState } from "react";
import TextEditor from "./TextEditor/TextEditor";
import './BlogTextBox.scss'
function BlogTextBox() {
  const [contentArray, setContentArray] = useState([]);

  const handleSave = (content) => {
    setContentArray([...contentArray, content]);
  };

  return (
    <div className="content">
      <TextEditor onSave={handleSave} />
      <ContentDisplay contentArray={contentArray} />
    </div>
  );
}

function ContentDisplay({ contentArray }) {
  return (
    <div >
      <h2>Saved Content:</h2>
      <ul>
        {contentArray.map((content, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: content }} />
        ))}
      </ul>
    </div>
  );
}

export default BlogTextBox;
