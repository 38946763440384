import React from 'react';
import './Why.scss';
import { FaCheck } from 'react-icons/fa';
import { MdArrowOutward } from "react-icons/md";

const Why = () => {
  return (
    <div className='why'>
        {/* <div className='why-container'>
            <div className='why-img'>
                <img src={require('../../assets/img/why.png')} class="logo-img" alt="f" />
            </div>
            <div className='why-content'>
                <p> Why Choose Us ?</p>
                <h1> Expertise in GST Registration and Auditing</h1>
                

                <div className='why-lists'>
                    <div>
                        <p> <span> <FaCheck /> </span> Professional Expertise</p>
                        <p> <span> <FaCheck /> </span> Compliance Assurance</p>
                        <p> <span> <FaCheck /> </span>  Cost-Effective  </p>

                    </div>
                    <div>
                        <p> <span> <FaCheck /> </span>   Timely Service</p>
                        <p> <span> <FaCheck /> </span>  Personalized Attention</p>
                        <p> <span> <FaCheck /> </span> Transparent Processes</p>


                    </div>

                </div>
            </div>

        </div> */}
        <div className='why-container'>
            <div className='why-content1'>
                <div className='why-content1-head'>
                    <span className='why-d'> why</span>
                    <span className='why-icon'> <MdArrowOutward /></span>
                </div>
                <div className='why-content1-text'>
                    <h2> Professional Expertise</h2>
                    <p> Tap into our extensive professional expertise to navigate the complexities of your business needs and ensure success with confidence</p>
                </div>

            </div>
            <div className='why-content1'>
                <div className='why-content1-head'>
                    <span className='why-d'> why</span>
                    <span className='why-icon'> <MdArrowOutward /></span>
                </div>
                <div className='why-content1-text'>
                    <h2> Timely Service</h2>
                    <p> Experience our commitment to punctuality, ensuring your needs are met with precision and efficiency through our prompt and reliable services</p>
                </div>

            </div>
            <div className='why-content1'>
                <div className='why-content1-head'>
                    <span className='why-d'> why</span>
                    <span className='why-icon'> <MdArrowOutward /></span>
                </div>
                <div className='why-content1-text'>
                    <h2> Transparent Processes</h2>
                    <p> Elevate your business confidence through our commitment to transparent processes, ensuring clarity and trust in every step of our services</p>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Why