import React, { useState, useEffect } from 'react';
import './Gstregistration.scss';
import Form from '../Form/Form';

const IncomeTax = () => {



    const [activeSection, setActiveSection] = useState('prop-document');

  // Scroll spy
  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = Array.from(document.querySelectorAll('.page_sect_cont')).map(section =>
        ({
          id: section.id,
          offset: section.offsetTop - 200,
          height: section.clientHeight
        })
      );

      const scrollPosition = window.scrollY;

      for (const { id, offset, height } of sectionOffsets) {
        if (offset <= scrollPosition && scrollPosition < offset + height) {
          setActiveSection(id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavLinkClick = (target) => {
    const element = document.getElementById(target);
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - 200,
      });
    }
  };


  return (
    <div className='service'>
      <div className='service-hero'>
        <div className='service-hero-container'>
            <div className='service-hero-content'>
              <h1>Income Tax Returns Online in India</h1>
              <p>Do you worry about the complexities of tax filing?</p>
              <p>Let our tax experts take care of it for you! Just share your details with us! </p>
            
            </div>
            <div className='service-hero-form'>
                <div className='service-hero-form-dis'>
                  <Form />
                </div>
            
            </div>
        </div>
              

        </div>
        <div className='service-container'>
            
            <div className='service-content'>
                <div className='service-side-nav'>
                        <div className="side-nav-list">
                            <h3>Our Services</h3>
                            <ul className="side-nav">
                                <li  className={activeSection === 'prop-document' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">Overview</a>
                                </li>
                                <li  className={activeSection === 'blue' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#alll">Benefits</a>
                                </li>
                                <li  className={activeSection === 'red' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">Documents Required</a>
                                </li>
                                <li  className={activeSection === 'green' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">Download GST </a>
                                </li>
                                <li  className={activeSection === 'faqs' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">FAQ s </a>
                                </li>
                                
                            </ul>
                        </div>
                
                </div>
                <div className='service-main-contents'>
                    <div className="page_sect_cont" id="prop-document">
                        <div className="page-content-title">
                            <h2>Income Tax Returns Online in India - An Overview</h2>
                            <p>Filing income tax returns is an essential obligation for every Indian citizen. To simplify the process and make it more accessible, the Government of India has introduced online filing of income tax returns. This convenient method allows taxpayers to submit their returns from the comfort of their homes or offices, saving time and effort. In this article, we provide an overview of the online income tax return filing process in India.</p>
                            
                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="blue">
                        <div className="page-content-title">
                            <h2>GST Registration Online in India or Goods and Service Tax - One Nation One Tax</h2>
                            <p>Being the biggest tax reform in India, GST is levied on goods and services. This includes both Central taxes (CST, Service Tax, Excise Duty) and State taxes (VAT, Entertainment Tax, Luxury Tax, Octroi). This will help the consumer to bear only GST charged by the last dealer in the chain of trade and supply. As per GST Regime, any company whose annual turnover is more than Rs.40 lakh is required to register as a normal taxable individual. However, there is an exclusion for hill states and the North East, where the annual turnover exceeds Rs.20 lakh for them.</p>
                            <p>As per GST Regime, any company whose annual turnover is more than Rs.40 lakh is required to register as a normal taxable individual. However, there is an exclusion for hill states and the North East, where the annual turnover exceeds Rs.20 lakh for them. Obtaining GST Registration in India is mandatory for all types of businesses whose turnover meets the above-mentioned criteria. If a business entity carries out any business activities without GST registration, it will be conceived as a punishable offense and the business and its owner will be liable to pay heavy penalties and legal issues.</p>
                        
                        </div>
                        <div className='page-content-paragraph'>
                            <h2>Benefits of itr Return Filing </h2>
                            <p> Paying income tax offers several benefits that contribute to the overall well-being of society. Here are some key advantages:</p>
                            <p><b>Public Services and Infrastructure: </b> Income tax revenue is crucial for funding essential public services and infrastructure. These include healthcare, education, transportation, public safety, and more. By paying income tax, you contribute to the development and maintenance of these services, which benefit everyone in the community. </p>
                            <p><b>Social Welfare Programs: </b> Income tax funds various social welfare programs aimed at assisting individuals and families in need. These programs can include unemployment benefits, welfare assistance, food stamps, housing support, and healthcare subsidies. Paying income tax ensures that vulnerable members of society receive the necessary support and care. </p>
                            <p><b>Economic Stability and Growth: </b> Income tax plays a vital role in maintaining economic stability and promoting growth. The revenue generated helps the government invest in infrastructure projects, create job opportunities, and stimulate economic development. By paying your fair share of income tax, you contribute to a robust and thriving economy. </p>
                            <p><b>Public Safety and Defense: </b> Income tax funds the military and other defense forces, ensuring national security and protecting citizens. It also supports law enforcement agencies, firefighters, and emergency services. Paying income tax helps maintain public safety and provides resources for responding to emergencies effectively. </p>
                            <p><b>Education and Skill Development: </b> Income tax revenue is allocated to education systems, including schools, colleges, and universities. This funding helps improve educational facilities, hire qualified teachers, and provide resources for students. By paying income tax, you contribute to the development of a knowledgeable and skilled workforce. </p>
                            <p><b>Healthcare and Research: </b> Income tax plays a significant role in supporting healthcare services and medical research. It helps fund hospitals, clinics, and healthcare programs, making quality medical care accessible to all. Additionally, income tax revenue supports scientific research and advancements in healthcare, contributing to better treatments and innovations. </p>
                            <p><b>Civic Responsibility: </b> Paying income tax is a civic duty that demonstrates your commitment to your country and community. It helps ensure a fair and just society where everyone contributes based on their means. By fulfilling your tax obligations, you participate in the collective effort to build a better future for all. </p>
                            


                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="red">
                        <div className="page-content-paragraph">
                        <h2>Documents Required for Income Tax Returns File</h2>
                            
                            <h4>Employees: </h4>
                                <ul>
                                    <li>PAN card </li>
                                    <li>Form 16 provided by your employer </li>
                                    <li>Salary slip (**Very essential for ITR filing online)  </li>
                                    
                                </ul>
                            <h4>Business owners, </h4>
                                <ul>
                                    <li>Trading report </li>
                                    <li>Business account details </li>
                                    <li>Profit and loss statement if any  </li>
                                    
                                </ul>
                            <h4>In addition to this, you can submit: </h4>
                                <ul>
                                    <li>Investment proofs </li>
                                    <li>Asset purchase/sale documents </li>
                                    <li>TDS certificates provided by banks  </li>
                                    <li>Interest income statement </li>
                                    <li>Receipts regarding donations from mutual funds and other forms of investment.  </li>
                                    
                                </ul>

                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="green">
                        <div className="page-content-title">
                            <h2>Download GST Registration Certificate</h2>
                            <p>GST Certificate is issued to people who are registered under GST. Those are having GST registration certificate are mandatorily required to display the registration certificate prominently at their place of business. Downloading GST certificate is a pretty easy process through the GST Portal. Login to the GST Account and and go to User Services. In User Services, click on View / Download Certificate to download GST registration certificate. </p>
                            <p>Any person or entity that wishes to supply goods or services can obtain GST registration voluntarily, irrespective of business turnover. Voluntarily obtaining GST registration can help the business avail Input Tax Credit and also provide GST bill to customers.</p>
                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="faqs">
                        <div className="page-content-faq">
                           
                        </div>
                        {/* Add your content for this section */}
                    </div>
                
                </div>
                
            </div>
        </div>
        
    </div>
  )
}

export default IncomeTax