import React, { useState } from "react";
import "./Signup.scss";
import { useDispatch } from "react-redux";
import {login } from "../../redux/slice";

const Signup = () => {

  const dispatch=useDispatch();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    console.log("Name:", name);
    console.log("Phone:", phone);
    console.log("Email:", email);
    console.log("Password:", password);

    dispatch(login(true))

    
  };


 
  return (
    <div className="signup">
      <div className="signup-container">
        <div className="signup-content">
          <div className="signup-content-img"></div>
          <div className="signup-content-form">
            <form>
              <div className="form-title">
                <h1>signup</h1>
              </div>
              <div className="form-list">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </div>
              <div className="form-list">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  required
                />
              </div>

              <div className="form-list">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="form-list">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="signup-btn"
                onClick={handleSubmit}
              >
                Signup
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
