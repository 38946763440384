import React, { useState, useEffect } from 'react';
import './Gstregistration.scss';
import Form from '../Form/Form';

const Gstregistration = () => {



    const [activeSection, setActiveSection] = useState('prop-document');

  // Scroll spy
  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = Array.from(document.querySelectorAll('.page_sect_cont')).map(section =>
        ({
          id: section.id,
          offset: section.offsetTop - 200,
          height: section.clientHeight
        })
      );

      const scrollPosition = window.scrollY;

      for (const { id, offset, height } of sectionOffsets) {
        if (offset <= scrollPosition && scrollPosition < offset + height) {
          setActiveSection(id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavLinkClick = (target) => {
    const element = document.getElementById(target);
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - 200,
      });
    }
  };


  return (
    <div className='service'>
      <div className='service-hero'>
        <div className='service-hero-container'>
            <div className='service-hero-content'>
              <h1>GST Registration</h1>
              <p>Your growing business needs GST compliance</p>
              <p>Registration and filing of GST can be done with expert assistance </p>
            
            </div>
            <div className='service-hero-form'>
                <div className='service-hero-form-dis'>
                  <Form />
                </div>
            
            </div>
        </div>
              

        </div>
        <div className='service-container'>
            
            <div className='service-content'>
                <div className='service-side-nav'>
                        <div className="side-nav-list">
                            <h3>Our Services</h3>
                            <ul className="side-nav">
                                <li  className={activeSection === 'prop-document' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">Overview</a>
                                </li>
                                <li  className={activeSection === 'blue' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#alll">GST Registration Online</a>
                                </li>
                                <li  className={activeSection === 'red' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">Documents Required</a>
                                </li>
                                <li  className={activeSection === 'green' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">Download GST </a>
                                </li>
                                <li  className={activeSection === 'faqs' ? 'active-side-nav' : ''} onClick={() => handleNavLinkClick('prop-document')} >
                                    <a href="#prop-document">FAQ s </a>
                                </li>
                                
                            </ul>
                        </div>
                
                </div>
                <div className='service-main-contents'>
                    <div className="page_sect_cont" id="prop-document">
                        <div className="page-content-title">
                            <h2>Overview of GST Registration Online in India</h2>
                            <p>Goods and Services Tax, or GST, is a tax levied on all products and services purchased in India. Taxation, value-added tax, and services tax are some indirect taxes replaced in India by the goods and services tax (GST), which is also an indirect tax. According to the Goods and Service Tax Act, which the Indian Government approved on March 29, 2017, the Goods and Service Tax (GST) has been in effect since July 1, 2017. As per GST jurisdiction, if you do business without GST registration, it is considered an offence.</p>
                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="blue">
                        <div className="page-content-title">
                            <h2>GST Registration Online in India or Goods and Service Tax - One Nation One Tax</h2>
                            <p>Being the biggest tax reform in India, GST is levied on goods and services. This includes both Central taxes (CST, Service Tax, Excise Duty) and State taxes (VAT, Entertainment Tax, Luxury Tax, Octroi). This will help the consumer to bear only GST charged by the last dealer in the chain of trade and supply. As per GST Regime, any company whose annual turnover is more than Rs.40 lakh is required to register as a normal taxable individual. However, there is an exclusion for hill states and the North East, where the annual turnover exceeds Rs.20 lakh for them.</p>
                            <p>As per GST Regime, any company whose annual turnover is more than Rs.40 lakh is required to register as a normal taxable individual. However, there is an exclusion for hill states and the North East, where the annual turnover exceeds Rs.20 lakh for them. Obtaining GST Registration in India is mandatory for all types of businesses whose turnover meets the above-mentioned criteria. If a business entity carries out any business activities without GST registration, it will be conceived as a punishable offense and the business and its owner will be liable to pay heavy penalties and legal issues.</p>
                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="red">
                        <div className="page-content-title">
                            <h2>Mandatory Documents For Online GST Registration</h2>
                        
                            <h3>Private Limited Company</h3>
                                <ul>
                                    <li>Certificate of Incorporation</li>
                                    <li>PAN Card of Company</li>
                                    <li>Articles of Association, AOA</li>
                                    <li>Memorandum of Association, MOA</li>
                                    <li>Resolution signed by board members</li>
                                    <li>Identity and address proof of directors</li>
                                    <li>Digital Signature</li>
                                    <li>Director's Proof</li>
                                </ul>
                            <h3>LLP</h3>
                                <ul>
                                    <li>PAN Card of LLP</li>
                                    <li>LLP Agreement</li>
                                    <li>Partner's names and address proof</li>
                                    <li>Director's Proof</li>
                                </ul>
                            <h3>Individual/Proprietorship</h3>
                                <ul>
                                    <li>PAN Card</li>
                                    <li>Address proof of proprietor</li>
                                    
                                </ul>

                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="green">
                        <div className="page-content-title">
                            <h2>Download GST Registration Certificate</h2>
                            <p>GST Certificate is issued to people who are registered under GST. Those are having GST registration certificate are mandatorily required to display the registration certificate prominently at their place of business. Downloading GST certificate is a pretty easy process through the GST Portal. Login to the GST Account and and go to User Services. In User Services, click on View / Download Certificate to download GST registration certificate. </p>
                            <p>Any person or entity that wishes to supply goods or services can obtain GST registration voluntarily, irrespective of business turnover. Voluntarily obtaining GST registration can help the business avail Input Tax Credit and also provide GST bill to customers.</p>
                        </div>
                        {/* Add your content for this section */}
                    </div>
                    <div className="page_sect_cont" id="faqs">
                        <div className="page-content-title">
                            <h2>Download GST Registration Certificate</h2>
                            <p>GST Certificate is issued to people who are registered under GST. Those are having GST registration certificate are mandatorily required to display the registration certificate prominently at their place of business. Downloading GST certificate is a pretty easy process through the GST Portal. Login to the GST Account and and go to User Services. In User Services, click on View / Download Certificate to download GST registration certificate. </p>
                            <p>Any person or entity that wishes to supply goods or services can obtain GST registration voluntarily, irrespective of business turnover. Voluntarily obtaining GST registration can help the business avail Input Tax Credit and also provide GST bill to customers.</p>
                        </div>
                        {/* Add your content for this section */}
                    </div>
                
                </div>
                
            </div>
        </div>
        
    </div>
  )
}

export default Gstregistration