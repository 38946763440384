import React from 'react';
import './Home.scss';


const Home = () => {
 


  return (
    <>
    
    <div className='home'>
              <div className='bg1'>
                  <div className='home-container ' >
                    <div className='home-text'>
                      <p className='title'>Welcome lebris</p>
                      <h1>  </h1>
                      <h1>Your Trusted Partner for GST Registration and Auditing Services</h1>
                      {/* <p>Are you looking for expert guidance in navigating the complex world of GST? At Authorised Audit, we specialize in providing comprehensive GST registration and auditing solutions tailored to your business needs.</p>
                       */}
                      <a href='/services' className='home-btn'>Enquiry Now  </a>

                    </div>
                    <div className='home-img'>
                        <img src={require('../../assets/img/home-img.png')}  alt="no-img" />
                    </div>
                  </div>
                </div>
                
    </div>
  
    </>
  )
}

export default Home