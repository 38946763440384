import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
        
        <div className='footer-container'>
        
            <div className='footer-content1'>
            <img src={require('../../assets/img/logo.png')} alt="no-img" />

            <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document on meaningful content.</p>
            
            <div className='footer-icons'>
                <a href='/'> <i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                <a href='/'> <i className="fa fa-facebook" aria-hidden="true"></i></a>
                <a href='/'> <i className="fa fa-instagram" aria-hidden="true"></i></a>
                <a href='/'> <i className="fa fa-twitter" aria-hidden="true"></i></a>
                
            </div>
            
            </div>
            <div className='footer-content2'>
                <h2>Quick Links</h2>

                <div className='quick-links'>
                    <a href="/">Home</a>
                    <a href="/about">About </a>
                    <a href="/services">Services </a>
                    <a href="/contact">Contact </a>
                </div>
            </div>
            <div className='footer-content3'>
                <h2>LEGAL</h2>

                <div className='help'>
                    <a href="/terms-conditions">Terms & Conditions </a>
                    <a href="/privacy-policy">Privacy Policy </a>
                </div>
            </div>
            <div className='footer-content4'>
                <h2>Contact US</h2>
                
                <div className='contact'>
                    <a href="/ "> lebris@gmail.com</a>
                    <a href="/ "> Level 7, IIFL TOWERS, 143, MGR Main Rd, Kandhanchavadi, Perungudi, Chennai, TamilNadu-600096</a>
                    
                </div>
                
            </div>

        </div>
        <div className='copy-right'>
            <p className='copy'>© 2021. All rights reserved </p> 
        </div>
    </div>
  )
}

export default Footer