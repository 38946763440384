import React from 'react';
import './About.scss';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className='about'>
        <div className='about-container'>
            <div className='about-content1' >
              <div className='about-text'>
                <h1> ABOUT Us </h1>
                
                <Link to={'/'} className='link-btn'> Learn More</Link>
              </div>

            </div>
            <div className='about-content2'>
              
              <div className='about-content2-text'>
              <p>At Lebris, we take pride in being a dynamic and client-centric company that stands as your reliable companion on the journey of business success. Established with a vision to provide comprehensive financial and compliance solutions, we specialize in GST registration, company registration, and income tax filing services, catering to businesses across India.</p>
              </div>
              {/* <div className='about-img'>
                <img src={require('../../assets/img/about.jpg')}  alt="" />
              
              </div> */}
            </div>
            
        </div>
    </div>
  )
}

export default About