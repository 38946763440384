import React , {useState } from 'react';
import './Login.scss';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';


const Login = () => {
  
  
  const dispatch=useDispatch()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);

    dispatch(login(true));
  };

  return (
    <div className='login'>
      <div className='login-container'>
        <div className='login-content'>
          <div className='login-content-img'>

          </div>
          <div className='login-content-form'>
            <form>
              <div className='form-title'>
                <h1>Login</h1>
              </div>
              <div className='form-list'>
                <label htmlFor="email">Email:</label>
                <input type="email"  id="email" name="email" value={email}  onChange={handleEmailChange} required  />
              </div>
              <div className='form-list'>
                <label htmlFor="password">Password:</label>
                <input type="password" id="password"   name="password"  value={password}    onChange={handlePasswordChange} required />
              </div>
              <button type="submit" className='login-btn' onClick={handleSubmit} >Log In</button>
            
            </form>            
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Login