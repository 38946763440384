import React from "react";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import Header from "../components/Header/Header";
import Home from "../components/Home/Home";
import Footer from "../components/Footer/Footer";
import About from "../components/About/About";
import Courses from "../components/Courses/Courses";
import Contact from "../components/Contact/Contact";
import Why from "../components/Why/Why";
import Login from "../components/Login/Login";
import Signup from "../components/Signup/Signup"
// import WhatWedo from "../components/WhatWedo/WhatWedo";
// import Ourclients from "../components/Ourclients/Ourclients";
import Faqs from "../components/Faqs/Faqs";
import ContactData from "../components/admin/ContactData/ContactData";
import Courseslist from "../components/Courses/Courseslist/Courseslist";

import Gstregistration from "../components/gstregistration/Gstregistration";
import Ourservices from "../components/Ourservices/Ourservices";
import IncomeTax from "../components/gstregistration/IncomeTax";
import Testimonials from "../components/Testimonials/Testimonials";





function Routing() {



  const Main = () =>{
    return(
        <>
        <Home />
        <About />
        <Ourservices />
        <Why />
        <Testimonials />
        {/* <Courses /> */}
        {/* <WhatWedo /> */}
        {/* <Ourclients /> */}

            
        </>
    );
}
  return (
    <>
      <Router>
        <Header />

        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-courses" element={<Courses />} />
            <Route path="/Why" element={<Why />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/user-login" element={<Login />} />
            <Route path="/user-signup" element={<Signup />} />
            <Route path="/faqs" element={<Faqs />} />

            .<Route path="/income-tax-filling" element={<IncomeTax />} />
            <Route path="/gst-registeration" element={<Gstregistration />} />
            <Route path="/contact-data" element={<ContactData />} />

            <Route path="/coureses-list" element={<Courseslist/>} />

        </Routes>

        

       <Footer />

      </Router>
      
  </>
  );
}

export default Routing;
