import React from 'react';
import './Ourservices.scss';

import { MdArrowOutward } from "react-icons/md";
import { PiArrowSquareUpRight } from "react-icons/pi";
import { Link } from 'react-router-dom';


const Ourservices = () => {
  return (
    <div className='our-services' >
        
        <div className='our-services-container'>
          <div className='our-services-content1'>
              <div className='our-services-text'>
                <h1> ABOUT Lebris</h1>
                
                
              </div>
            
          </div>
          <div className='our-services-content2'>
            <div className='our-services-head'>
                <h1>Our services</h1>
                
                
            </div>
            <div className='our-services-cards'>
              <div className='our-services-card1'>
                <div className='card1-text'>
                  <h2> GST REGISTRATION</h2>
                  <p>Ensure compliance with Goods and Services Tax regulations with our efficient GST registration services. We guide you through the process, making it hassle-free and ensuring that your business stays on the right side of the law.</p>
                </div>
                <div className='card1-icon-box'>
                  <span className='icon-box'>
                  <Link to={'/gst-registeration'}> <MdArrowOutward /></Link>
                  </span>
                </div>

              </div>
              <div className='our-services-card1'>
                <div className='card1-text'>
                  <h2> COMPANY  REGISTRATION</h2>
                  <p>Embark on your entrepreneurial journey with confidence by entrusting us with your company registration. Our experts streamline the registration process, providing you with the necessary legal foundation to build your business.</p>
                </div>
                <div className='card1-icon-box'>
                  <span className='icon-box'>
                    <Link to={'/'}> <MdArrowOutward /></Link>
                  </span>
                </div>

              </div>
              <div className='our-services-card1'>
                <div className='card1-text'>
                  <h2> INCOME TAX FILLING</h2>
                  <p>Simplify the income tax filing process with our comprehensive services. We assist individuals and businesses in meeting their tax obligations while maximizing available exemptions and deductions.</p>
                </div>
                <div className='card1-icon-box'>
                  <span className='icon-box'>
                    <Link to={'/'}> <MdArrowOutward /></Link>
                  </span>
                </div>

              </div>

            </div>
            
          </div>

        </div>

    </div>
  )
}

export default Ourservices