import React, { useState } from 'react';
import './Faqs.scss';
import { FaPlus, FaMinus } from 'react-icons/fa';

const faqData = [
  {
    question: "What services does ABC Graphics offer?",
    answer: "ABC Graphics specializes in T-shirt design, embroidery design, and graphic design services. We create custom designs tailored to your needs."
  },
  {
    question: "Can I request a custom T-shirt design?",
    answer: "Yes, absolutely! We offer custom T-shirt design services, allowing you to create unique and personalized designs for any occasion or purpose."
  },
  {
    question: "What types of embroidery projects can you handle?",
    answer: "We can handle a wide range of embroidery projects, including apparel, hats, bags, and more. Whether you need company logos, monograms, or intricate patterns, we've got you covered."
  },
  {
    question: "What industries do you serve with your graphic design services?",
    answer: "We serve various industries, including corporate, fashion, sports, and more. Our versatile team can handle a wide range of design projects."
  },
  {
    question: "How do I request a quote for your services?",
    answer: "Requesting a quote is easy! Simply visit our 'Contact Us' page and fill out the provided form. We'll get back to you promptly with a personalized quote."
  },
  {
    question: "Can you provide design samples of your previous work?",
    answer: "Of course! Visit our 'Portfolio' section to view samples of our work in T-shirt design, embroidery, and graphic design. These examples showcase our creativity and quality."
  },
  {
    question: "What is the typical turnaround time for design projects?",
    answer: "Turnaround times can vary depending on the complexity of the project and our current workload. We will provide you with an estimated timeline when you request a quote."
  },
  {
    question: "Do you offer rush services for urgent projects?",
    answer: "Yes, we offer rush services for clients with tight deadlines. Please let us know your timeline when you contact us, and we'll do our best to accommodate your needs."
  },
  {
    question: "How do I get started with ABC Graphics?",
    answer: "Getting started is easy. Contact us via the information provided on our website, and we'll guide you through the process of discussing your project, providing a quote, and initiating the design work."
  }
];



const Faqs = () => {

  const [openIndex, setOpenIndex] = useState(0); // Initialize with 0 for the first question open

  const toggleAnswer = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };


  return (
    <div className='faq'>
        <div className='faq-container'>
            <div className='faq-head'>
              <h1>
              Frequently Asked Questions
              </h1>


            </div>
            <div className='faq-content'>

              {faqData.map((item, index) => (
                <div key={index} className='faq1'>
                  <h3 onClick={() => toggleAnswer(index)} style={{ cursor: 'pointer' }}>
                    {item.question}
                    {openIndex === index ? <FaMinus /> : <FaPlus />}
                  </h3>
                  <div className="faq-answer">
                    {openIndex === index && <p>{item.answer}</p>}
                  </div>
                </div>
              ))}

            </div>
        </div>
        

    </div>
  )
}

export default Faqs